import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot } from "@angular/router";
import { AuthenticationService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private authService: AuthenticationService, 
    private auth: AuthenticationService, 
    private router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    console.log(this.auth.isLoggedIn);

    const user = await this.authService.IsLoggedIn()
    if (!user) {
      this.router.navigate(["login"]);
      return false;
    } 
    /*
    if (!this.auth.isLoggedIn) {
      this.router.navigate(["login"]);
      return false;
    }*/

    return true;
  }
}
