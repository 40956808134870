// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCLelyNH2M2_ZPyJyPCahkCPUynYFJZrG4",
    authDomain: "thndr-games-sandbox.firebaseapp.com",
    databaseURL: "https://thndr-games-sandbox.firebaseio.com",
    projectId: "thndr-games-sandbox",
    storageBucket: "thndr-games-sandbox.appspot.com",
    messagingSenderId: "676089157136",
    appId: "1:676089157136:web:889d8644c6bafb1a7d5c95",
    measurementId: "G-FQ3H18RTJ5"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
