export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCLelyNH2M2_ZPyJyPCahkCPUynYFJZrG4",
    authDomain: "thndr-games-sandbox.firebaseapp.com",
    databaseURL: "https://thndr-games-sandbox.firebaseio.com",
    projectId: "thndr-games-sandbox",
    storageBucket: "thndr-games-sandbox.appspot.com",
    messagingSenderId: "676089157136",
    appId: "1:676089157136:web:889d8644c6bafb1a7d5c95",
    measurementId: "G-FQ3H18RTJ5"
  }
}