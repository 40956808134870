import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from './shared/auth.service';
import { auth } from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Games',
      url: '/games',
      icon: 'game-controller'
    },
    {
      title: 'Tournaments',
      url: '/tournaments',
      icon: 'trophy'
    },
    {
      title: 'Shield',
      url: '/shield',
      icon: 'shield'
    },
    {
      title: 'Ads',
      url: '/ads',
      icon: 'newspaper'
    },
    {
      title: 'Pub',
      url: '/pub',
      icon: 'bar-chart'
    }/*,
    {
      title: 'Advertise',
      url: '/advertise',
      icon: 'newspaper'
    },
    {
      title: 'Help',
      url: '/help',
      icon: 'help-circle'
    }*/
  ];

  constructor(
    private authService: AuthenticationService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname;
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  logOut() {
    this.authService.SignOut()
  }
}
